<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">Valoraciones de {{ business.shortName }} </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <!-- <v-col cols="12" sm="12" md="12" class="text-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <v-btn
                        color="rgba(0, 0, 0, 0.6)"
                        fab
                        v-on="on"
                        class="mr-1"
                        dark
                        :elevation="0"
                        :minHeight="56"
                        :loading="loading"
                        @click="toExcel"
                    >
                        <v-icon x-large dark>fa-file-excel</v-icon>
                    </v-btn>
                    </template>
                    <span>Exportar .CSV</span>
                </v-tooltip>
            </v-col> -->

            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="getBusinessRatings"
                :items-per-page="10"
                :loading="loading"
                :sort-desc="true"
                :single-expand="true"
                item-key=".key"
                :expanded.sync="expanded"
                show-expand
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.isDriverRatedGood`]="{ item }">
                  <v-chip
                    v-if="item"
                    class="ma-2"
                    :color="item.isDriverRatedGood ? 'green' : 'red'"
                    text-color="white"
                  >
                    <i
                      v-if="item.isDriverRatedGood"
                      class="fas fa-thumbs-up mr-3"
                    >
                    </i>
                    <i v-else class="fas fa-thumbs-down mr-3"> </i>
                    {{ item.isDriverRatedGood ? "Positiva" : "Negativa" }}
                  </v-chip>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="pa-4">
                    <v-card :elevation="0" class="d-flex justify-space-between">
                      <v-card
                        class="text-left px-1 mx-1"
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        elevation="0"
                        min-width="250"
                        style="border-radius: 17px !important; overflow: hidden"
                      >
                        <v-card-text class="text--primary">
                          <div class="mb-n2">Ocho</div>
                          <p class="title ma-0" style="color: #f06a25">
                            Metadata:
                          </p>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Order ID en FireStore</span
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="copyToClipboard(item['.key'])"
                                >{{ item[".key"] }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >User ID en FireStore</span
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="copyToClipboard(item.userId)"
                                >{{ item.userId }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Business ID en FireStore</span
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="copyToClipboard(item.businessId)"
                                >{{ item.businessId }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Sistema Operativo</span
                          >
                          <span style="d-block">{{
                            item.orderChannel == "App_android"
                              ? "Android"
                              : "iOS"
                          }}</span>
                        </v-card-text>
                      </v-card>

                      <v-card
                        class="text-left px-1 mx-1 flex-grow-1"
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        elevation="0"
                        style="border-radius: 17px !important; overflow: hidden"
                      >
                        <v-card-text class="text--primary">
                          <div class="mb-n2">{{ item.userName }}</div>
                          <p class="title ma-0" style="color: #f06a25">
                            Detalles del Cliente:
                          </p>
                          <v-divider class="my-2"></v-divider>

                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <span class="d-block font-weight-bold"
                                >Método de pago</span
                              >
                              <v-chip
                                :color="
                                  item.orderPaymentMethod == 'tengo'
                                    ? 'indigo'
                                    : item.orderPaymentMethod == 'card'
                                    ? 'pink '
                                    : 'light-green'
                                "
                                style="display: inline-block"
                                dark
                                small
                                class="font-weight-bold"
                                :text-color="'white'"
                                >{{ item.orderPaymentMethod }}
                              </v-chip>
                            </v-col>
                          </v-row>

                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Teléfono del Cliente</span
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="copyToClipboard(item.userPhone)"
                                >{{ item.userPhone }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Cantidad de órdenes</span
                          >
                          {{ item.orderCounter || 0 }}
                          <v-divider class="my-2"></v-divider>
                          <span
                            v-if="item.userEmail"
                            class="d-block font-weight-bold"
                            >Correo electrónico del Cliente</span
                          >
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                :color="
                                  $vuetify.theme.dark
                                    ? 'rgba(0, 0, 0, 0.4)'
                                    : 'rgba(0, 0, 0, 0.1)'
                                "
                                style="display: inline-block"
                                v-on="on"
                                dark
                                :text-color="
                                  $vuetify.theme.dark ? 'white' : 'black'
                                "
                                @click="copyToClipboard(item.userEmail)"
                                >{{ item.userEmail }}</v-chip
                              >
                            </template>
                            <i class="far fa-copy mr-2"></i>
                            <span>Click para copiar</span>
                          </v-tooltip>
                          <v-divider class="my-2"></v-divider>
                          <v-row>
                            <v-col cols="12" sm="6" md="6">
                              <span class="d-block font-weight-bold"
                                >Dirección del Cliente:</span
                              >
                              <a
                                style="display: block"
                                :href="
                                  'https://www.google.com/maps/search/?api=1&query=' +
                                  item.userAddressLatitude +
                                  ',' +
                                  item.userAddressLongitude
                                "
                                target="_blank"
                              >
                                {{
                                  item.userAddressLatitude
                                    ? parseFloat(
                                        item.userAddressLatitude
                                      ).toFixed(6) + ","
                                    : "no disponible"
                                }}{{
                                  item.userAddressLongitude
                                    ? parseFloat(
                                        item.userAddressLongitude
                                      ).toFixed(6)
                                    : ""
                                }}
                                <i class="ml-1 fas fa-external-link-alt"></i>
                              </a>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" v-if="item.location">
                              <span class="d-block font-weight-bold"
                                >Ubicación Actual:</span
                              >
                              <a
                                style="display: block"
                                :href="
                                  'https://www.google.com/maps/search/?api=1&query=' +
                                  item.location[0] +
                                  ',' +
                                  item.location[1]
                                "
                                target="_blank"
                              >
                                {{
                                  item.location[0]
                                    ? parseFloat(item.location[0]).toFixed(6) +
                                      ","
                                    : "no disponible"
                                }}{{
                                  item.location[1]
                                    ? parseFloat(item.location[1]).toFixed(6)
                                    : ""
                                }}
                                <i class="ml-1 fas fa-external-link-alt"></i>
                              </a>
                            </v-col>
                          </v-row>
                          <span class="d-block">{{
                            item.userAddressName
                          }}</span>
                          <div class="d-block">
                            {{ item.userAddressNeighborhood }}
                          </div>
                        </v-card-text>
                      </v-card>

                      <v-card
                        class="text-left px-1 mx-1 flex-grow-1"
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        elevation="0"
                        min-width="200"
                        style="border-radius: 17px !important; overflow: hidden"
                      >
                        <v-card-text class="text--primary">
                          <div class="mb-n2">{{ item.businessName }}</div>
                          <p class="title ma-0" style="color: #f06a25">
                            Detalles del Negocio:
                          </p>
                          <template v-if="item.businessPhone">
                            <v-divider class="my-2"></v-divider>
                            <span class="d-block font-weight-bold"
                              >Teléfono del Negocio</span
                            >
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  :color="
                                    $vuetify.theme.dark
                                      ? 'rgba(0, 0, 0, 0.4)'
                                      : 'rgba(0, 0, 0, 0.1)'
                                  "
                                  style="display: inline-block"
                                  v-on="on"
                                  dark
                                  :text-color="
                                    $vuetify.theme.dark ? 'white' : 'black'
                                  "
                                  @click="copyToClipboard(item.businessPhone)"
                                  >{{ item.businessPhone }}</v-chip
                                >
                              </template>
                              <i class="far fa-copy mr-2"></i>
                              <span>Click para copiar</span>
                            </v-tooltip>
                          </template>

                          <v-divider class="my-2"></v-divider>
                          <span class="d-block font-weight-bold"
                            >Dirección del Negocio:</span
                          >
                          <div class="d-block">{{ item.businessZone }}</div>
                          <template v-if="item.deliveryInfo">
                            <template v-if="item.deliveryInfo.driverName">
                              <v-divider class="my-2"></v-divider>
                              <span class="d-block font-weight-bold"
                                >Motorista Asignado</span
                              >
                              <span
                                class="d-block"
                                style="text-transform: capitalize"
                                >{{ item.deliveryInfo.driverName }}</span
                              >
                              <v-divider class="my-2"></v-divider>
                              <span class="d-block font-weight-bold"
                                >Teléfono del Motorista</span
                              >
                              <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                  <v-chip
                                    :color="
                                      $vuetify.theme.dark
                                        ? 'rgba(0, 0, 0, 0.4)'
                                        : 'rgba(0, 0, 0, 0.1)'
                                    "
                                    style="display: inline-block"
                                    v-on="on"
                                    dark
                                    :text-color="
                                      $vuetify.theme.dark ? 'white' : 'black'
                                    "
                                    @click="
                                      copyToClipboard(item.deliveryInfo.phone)
                                    "
                                    >{{ item.deliveryInfo.phone }}</v-chip
                                  >
                                </template>
                                <i class="far fa-copy mr-2"></i>
                                <span>Click para copiar</span>
                              </v-tooltip>
                            </template>
                          </template>
                        </v-card-text>
                      </v-card>

                      <v-card
                        class="text-left px-1 mx-1"
                        max-width="500"
                        min-width="400"
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        elevation="0"
                        style="border-radius: 17px !important; overflow: hidden"
                      >
                        <v-card-text class="text--primary">
                          <div class="mb-n2">{{ item.shortId }}</div>
                          <p class="title ma-0" style="color: #f06a25">
                            Detalles de la Orden:
                          </p>
                          <template v-if="item.tinName && item.tinNumber">
                            <v-divider class="my-2"></v-divider>
                            <span class="d-block font-weight-bold text--red"
                              >Con RTN</span
                            >
                            <span style="d-block">{{ item.tinName }}</span>
                            <br />
                            <span style="d-block">{{ item.tinNumber }}</span>
                          </template>
                          <div
                            class="d-block"
                            v-for="(product, itemObjKey) in item.products"
                            :key="
                              product.id
                                ? itemObjKey + 1 + product.id + item['.key']
                                : itemObjKey + 1 + item['.key']
                            "
                          >
                            <v-divider class="my-2"></v-divider>
                            <v-row>
                              <v-col cols="12" sm="8" md="8">
                                <span
                                  class="d-block subtitle-1 font-weight-bold"
                                >
                                  <v-avatar
                                    class="mb-1 mr-1"
                                    color="primary"
                                    size="21"
                                  >
                                    <span
                                      class="white--text font-weight-bold"
                                      style="font-size: 15px"
                                      >{{ product.quantity }}</span
                                    >
                                  </v-avatar>
                                  {{ product.name }}
                                </span>
                              </v-col>
                              <v-col cols="12" sm="4" md="4">
                                <span
                                  class="font-weight-bold"
                                  style="font-size: 15px"
                                  >{{
                                    product.priceSubTotal.toFixed(2) | currency
                                  }}
                                </span>
                              </v-col>
                            </v-row>
                            <span
                              v-if="product.comments"
                              style="white-space: pre-wrap"
                              >{{ product.comments }}</span
                            >
                            <span v-else class="caption" style="opacity: 0.4"
                              >Producto sin detalles/comentarios</span
                            >

                            <span
                              style="font-size: 15px"
                              class="d-block font-weight-bold ml-2 mt-3"
                              v-if="product.addons && product.addons.length > 0"
                              >Complementos:
                            </span>

                            <div
                              v-for="(addon, i) in groupAddons(product.addons)"
                              :key="i"
                              class="d-block subtitle-1"
                            >
                              <span style="font-size: 15px" class="ml-5"
                                >- {{ addon.groupName }}</span
                              >
                              <div
                                v-for="(option, optKey) in addon.options"
                                :key="optKey"
                                style="font-size: 13px"
                                class="ml-8 font-weight-bold"
                              >
                                <v-avatar
                                  class="mb-1 mr-1"
                                  color="grey"
                                  size="20"
                                >
                                  <span
                                    class="white--text"
                                    style="font-size: 12px"
                                    >{{ option.quantity }}
                                  </span>
                                </v-avatar>
                                {{ option.name }} | {{ addon.addonPrice }}
                              </div>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card>
                  </td>
                </template>

                <template v-slot:[`item.isBusinessRatedGood`]="{ item }">
                  <v-chip
                    v-if="item"
                    class="ma-2"
                    :color="item.isBusinessRatedGood ? 'green' : 'red'"
                    text-color="white"
                  >
                    <i
                      v-if="item.isBusinessRatedGood"
                      class="fas fa-thumbs-up mr-3"
                    >
                    </i>
                    <i v-else class="fas fa-thumbs-down mr-3"> </i>
                    {{ item.isBusinessRatedGood ? "Positiva" : "Negativa" }}
                  </v-chip>
                </template>

                <template v-slot:[`item.progressCreatedAt`]="{ item }">
                  {{ item.progressCreatedAt | filterDate }}
                </template>

                <template v-slot:[`item.orderTotal`]="{ item }">
                  {{ item.orderTotal | currency }}
                </template>

                <template v-slot:[`item.deliveryCost`]="{ item }">
                  {{ item.deliveryCost | currency }}
                </template>

                <template v-slot:[`item.shortId`]="{ item }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        :color="
                          $vuetify.theme.dark
                            ? 'rgba(0, 0, 0, 0.4)'
                            : 'rgba(0, 0, 0, 0.1)'
                        "
                        style="display: inline-block"
                        v-on="on"
                        dark
                        :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                        @click="copyToClipboard(item.shortId)"
                        >{{ item.shortId }}</v-chip
                      >
                    </template>
                    <i class="far fa-copy mr-2"></i>
                    <span>Click para copiar</span>
                  </v-tooltip>
                </template>

                <template v-slot:loading>
                  <div class="ma-5" style="position: relative">
                    <div
                      style="
                        position: absolute;
                        z-index: 999;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <lottie
                        :options="defaultOptions"
                        :height="300"
                        :width="400"
                        v-on:animCreated="handleAnimation"
                      />
                      <p
                        :class="
                          $vuetify.theme.dark
                            ? 'subtitle-1 primary--text'
                            : 'subtitle-1 black--text'
                        "
                      >
                        Cargando Data
                      </p>
                    </div>
                    <v-skeleton-loader
                      ref="skeleton"
                      type="table-tbody"
                      class="mx-auto d-none d-md-block"
                    ></v-skeleton-loader>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import { mapActions, mapState } from "vuex";

export default {
  name: "ratings-details",
  props: ["business", "ratings", "date"],
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      snackbarText: "",
      saving: false,
      orders: [],
      loading: true,
      expanded: [],
      headers: [
        {
          text: "Orden ID",
          value: "shortId",
        },
        {
          text: "Usuario",
          value: "userName",
          width: "300px",
        },
        {
          text: "Total de la orden",
          value: "orderTotal",
          width: "150px",
        },
        {
          text: "Costo de envío",
          value: "deliveryCost",
          width: "150px",
        },
        {
          text: "Fecha de creación",
          value: "progressCreatedAt",
          width: "200px",
        },
        {
          text: "Valoración al motociclista",
          value: "isDriverRatedGood",
        },
        {
          text: "Valoración al comercio",
          value: "isBusinessRatedGood",
        },
        {
          text: "Tiempo colocada-aceptada",
          value: "durationPlacedAcepted",
          width: "100px",
        },
        {
          text: "Tiempo aceptada-recogida",
          value: "durationAcceptedPickup",
          width: "100px",
        },
        {
          text: "Tiempo recogida-entregada",
          value: "durationBusinessDelivered",
          width: "100px",
        },
        {
          text: "Tiempo total de la orden",
          value: "orderDuration",
          width: "100px",
        },
      ],
    };
  },

  computed: {
    getBusinessRatings() {
      //   let businessesRatings = this.ratings.filter(
      //     (rating) => rating.businessId == this.business[".key"]
      //   );

      //   if (this.orders.length > 0) {
      return this.orders.map((result) => {
        let rating = this.ratings.find((e) => e.orderId == result[".key"]);
        // let result = this.orders.find(
        //   (order) => order[".key"] === rating.orderId
        // );
        // if (result) {
        result.isBusinessRatedGood = rating.isBusinessRatedGood;
        result.isDriverRatedGood = rating.isDriverRatedGood;

        let progressCreatedAt = moment(result.progressCreatedAt.toDate());
        let progressPlacedAt = result.progressPlacedAt
          ? moment(result.progressPlacedAt.toDate())
          : moment([2008, 9]);
        let progressAcceptedAt = result.progressAcceptedAt
          ? moment(result.progressAcceptedAt.toDate())
          : moment([2008, 9]);
        let progressDeliveryStartedAt = result.progressDeliveryStartedAt
          ? moment(result.progressDeliveryStartedAt.toDate())
          : moment([2008, 9]);
        let progressDeliveredAt = result.progressDeliveredAt
          ? moment(result.progressDeliveredAt.toDate())
          : moment([2008, 9]);

        result.durationPlacedAcepted = `${progressAcceptedAt.diff(
          progressPlacedAt,
          "minutes"
        )} min.`;
        result.durationAcceptedPickup = `${progressDeliveryStartedAt.diff(
          progressAcceptedAt,
          "minutes"
        )} min.`;
        result.durationBusinessDelivered = `${progressDeliveredAt.diff(
          progressDeliveryStartedAt,
          "minutes"
        )} min.`;
        result.orderDuration = `${progressDeliveredAt.diff(
          progressCreatedAt,
          "minutes"
        )} min.`;
        // }
        return result;
      });
      //   } else {
      //     return businessesRatings;
      //   }
    },
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("L, h:mm A");
    },
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    groupAddons(addons) {
      let result = [];
      if (addons) {
        addons.forEach((addon) => {
          let search = result.find((item) => item.groupName == addon.groupName);
          if (search) {
            search.options.push({ name: addon.name, quantity: addon.quantity });
          } else {
            let newAddon = addon;
            newAddon.options = [{ name: addon.name, quantity: addon.quantity }];
            result.push(newAddon);
          }
        });
      }
      return result;
    },
    toExcel() {
      const filename = `Valoraciones de ${this.business.shortName} ${this.date[0]}/${this.date[1]}.csv`;
      const fields = [
        {
          label: "ID de la orden",
          value: "shortId",
        },
        {
          label: "Total de la orden",
          value: "orderTotal",
        },
        {
          label: "Fecha de creación",
          value: (row) => {
            try {
              return `${moment(row.progressCreatedAt.toDate())
                .tz("America/Tegucigalpa")
                .format("L, h:mm A")}`;
            } catch (error) {
              return `No Disponible`;
            }
          },
        },
        {
          label: "Valoracion al comercio",
          value: (row) => {
            try {
              return `${row.isBusinessRatedGood ? "Positiva" : "Negativa"}`;
            } catch (error) {
              return `No Disponible`;
            }
          },
        },
        {
          label: "Tiempo aceptada-recogida",
          value: "durationAcceptedPickup",
        },
        {
          label: "Tiempo total de la orden",
          value: "orderDuration",
        },
        // {
        //   label: "Porcentaje de valoraciones positivas",
        //   value: (row) => {
        //     try {
        //       return `${
        //         row.goodPercentageRating > 0 ? row.goodPercentageRating : 0
        //       }`;
        //     } catch (error) {
        //       return `No Disponible`;
        //     }
        //   },
        // },
      ];

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.getBusinessRatings);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
  },

  async mounted() {
    // console.log(this.ratings);
    let orders = this.ratings.filter(
      (rating) => rating.businessId == this.business[".key"]
    );

    orders = orders.map((e) => {
      return e.orderId;
    });

    if (orders.length == 0) {
      this.loading = false;
      return;
    }

    const dataArr = new Set(orders);
    let ordersId = [...dataArr];

    for (let i = 0; i <= ordersId.length; i = i + 10) {
      let elements = ordersId.slice(i, i + 10);
      if (elements && elements.length) {
        let response = await db
          .collection("orders")
          .where(fb.firestore.FieldPath.documentId(), "in", elements)
          .get();

        response.docs.forEach((order) => {
          let data = order.data();
          data[".key"] = order.id;
          this.orders.push(data);
        });
      }
    }

    this.loading = false;
  },
};
</script>
