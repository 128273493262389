<template>
  <div>
    <v-container fluid class="fill-height">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" sm="4" md="4">
          <h1>
            Valoración de
            <span class="font-weight-light">comercios</span>
          </h1>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                label="Filtrar por fecha"
                prepend-icon="fa-calendar-alt"
                readonly
                filled
                rounded
                hide-details
                :disabled="loading"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              :title-date-format="title"
              scrollable
              :max="today"
            >
              <v-row no-gutters style="flex-wrap: nowrap">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="primary"
                  @click="modal = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="primary"
                  @click="
                    $refs.dialog.save(date);
                    updateDateFilter();
                  "
                  >Filtrar</v-btn
                >
              </v-row>
            </v-date-picker>
          </v-dialog>
        </v-col>

        <v-col cols="12" sm="2" md="2" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                color="rgba(0, 0, 0, 0.6)"
                fab
                v-on="on"
                class="mr-1"
                dark
                :elevation="0"
                :minHeight="56"
                :loading="loading"
                @click="toExcel"
              >
                <v-icon x-large dark>fa-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar .CSV</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="businesses"
            :items-per-page="10"
            :loading="loading"
            sort-by="totalRatings"
            :search="$store.state.search"
            :sort-desc="true"
            item-key=".key"
            fixed-header
            :single-expand="true"
          >
            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block; text-transform: capitalize"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.phone)"
                    >{{ item.phone }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-avatar v-if="item.logo && item.logo.original" class="mt-4">
                <img :src="item.logo.original" :alt="item.name" />
              </v-avatar>
            </template>

            <template v-slot:[`item.goodPercentageRating`]="{ item }">
              <v-chip
                class="ma-2"
                :color="item.goodPercentageRating > 0 ? 'green' : 'red'"
                text-color="white"
              >
                {{
                  item.goodPercentageRating > 0 ? item.goodPercentageRating : 0
                }}
                %
              </v-chip>
            </template>

            <template v-slot:[`item.negativeRatings`]="{ item }">
              {{ item.totalRatings - item.goodRatings }}
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="viewRatings(item)" small color="primary"
                >Ver valoraciones</v-btn
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="selectedBusiness"
      v-model="modalRatingsDetails"
      max-width="90%"
    >
      <ratings-details
        :ratings="ratings"
        :date="date"
        @cancel="modalRatingsDetails = false"
        :key="selectedBusiness['.key']"
        :business="selectedBusiness"
      ></ratings-details>
    </v-dialog>

    <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import * as animationData from "@/assets/ochoColor.json";
import { Parser } from "json2csv";
import axios from "axios";
import ratingsDetails from "./ratings-details";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "bikers-rating",
  components: {
    lottie: Lottie,
    ratingsDetails,
  },
  data() {
    return {
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      anim: null,
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      snackbar: false,
      ratings: [],
      businesses: [],
      snackbarText: null,
      loading: true,
      currentTime: Date.now(),
      modalRatingsDetails: false,
      selectedBusiness: null,
      headers: [
        {
          value: "image",
        },
        // {
        //   text: "Driver Id",
        //   value: "fleet_id",
        // },
        {
          text: "Nombre",
          value: "shortName",
          width: "250px",
          align: "center",
        },
        // {
        //   text: "Telefono",
        //   value: "phone",
        // },
        // {
        //   text: "Correo",
        //   value: "email",
        // },
        {
          text: "Total de valoraciones",
          value: "totalRatings",
          align: "center",
        },

        {
          text: "Valoraciones positivas",
          value: "goodRatings",
          align: "center",
        },
        {
          text: "Valoraciones negativas",
          value: "negativeRatings",
          align: "center",
        },
        {
          text: "Porcentaje de valoraciones positiva",
          value: "goodPercentageRating",
          align: "center",
        },
        {
          value: "options",
          align: "end",
        },
      ],
    };
  },
  computed: {
    search() {
      return this.$store.state.search;
    },

    today() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  methods: {
    title() {
      return "Selecciona las fechas a filtrar";
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    toExcel() {
      const filename = `valoraciones de comercios ${this.date[0]}/${this.date[1]}.csv`;
      const fields = [
        {
          label: "Nombre del comercio",
          value: "shortName",
        },
        {
          label: "Total de valoraciones",
          value: "totalRatings",
        },
        {
          label: "Valoraciones positivas",
          value: "goodRatings",
        },
        {
          label: "Valoraciones negativas",
          value: (row) => {
            try {
              return `${row.totalRatings - row.goodRatings}`;
            } catch (error) {
              return `No Disponible`;
            }
          },
        },
        {
          label: "Porcentaje de valoraciones positivas",
          value: (row) => {
            try {
              return `${
                row.goodPercentageRating > 0 ? row.goodPercentageRating : 0
              }`;
            } catch (error) {
              return `No Disponible`;
            }
          },
        },
      ];

      const json2csvParser = new Parser({
        fields,
        excelStrings: true,
        withBOM: true,
      });
      const csv = json2csvParser.parse(this.businesses);

      let blob = new Blob([csv], { type: "text/csv" });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
      }
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }

      this.getBusinessesRatings();
    },

    async getBusinessesRatings() {
      this.loading = true;
      this.businesses = [];

      let query = db
        .collection(`ratings`)
        .where(
          "answeredAt",
          ">",
          moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
        )
        .where(
          "answeredAt",
          "<",
          moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
        );

      await this.$binding("ratings", query);

      this.ratings = this.ratings.filter((e) => e.answered);

      let businessesId = this.ratings.map((item) => {
        return item.businessId;
      });

      businessesId = businessesId.filter((e) => e && e != "N/A");

      businessesId = [...new Set(businessesId)];

      for (let i = 0; i <= businessesId.length; i = i + 10) {
        let elements = businessesId.slice(i, i + 10);

        if (elements && elements.length) {
          let response = await db
            .collection("businesses")
            .where(fb.firestore.FieldPath.documentId(), "in", elements)
            .get();

          response.docs.forEach((business) => {
            let data = business.data();

            data[".key"] = business.id;
            let businessRatings = this.ratings.filter(
              (item) => item.businessId == data[".key"]
            );
            let goodRatings = businessRatings.filter(
              (item) => item.isBusinessRatedGood
            );
            data.totalRatings = businessRatings.length;
            data.goodRatings = goodRatings.length;
            data.negativeRatings = data.totalRatings - data.goodRatings;
            data.goodPercentageRating = parseInt(
              (goodRatings.length / businessRatings.length) * 100
            );
            this.businesses.push(data);
          });
        }
      }

      this.loading = false;
    },

    viewRatings(item) {
      this.selectedBusiness = item;
      this.modalRatingsDetails = true;
    },
  },
  destroyed() {
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
  async mounted() {
    // document
    //   .getElementsByClassName("v-data-table__wrapper")[0]
    //   .addEventListener("scroll", function(e) {
    //     document.body.scrollTop = document.documentElement.scrollTop = document.getElementsByClassName(
    //       "v-data-table__wrapper"
    //     )[0].scrollTop;
    //   });

    this.loading = true;
    this.$store.commit("setSearchTerm", "motociclista");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.getBusinessesRatings();
  },
};
</script>
